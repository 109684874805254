import axios from 'axios';
import React from 'react'
import {StylesConfig} from 'react-select'
import AsyncSelect from 'react-select/async';

const selectStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: '42px',
    border: '1px solid #e4e6ef',
    outline: 0,
    borderRadius: '0.475rem',
    boxShadow: 'none',
    '&:focus': {
      borderColor: '#b5b5c3',
      outline: 0,
      boxShadow: 'none'
    },
    '&:hover': {
      borderColor: '#b5b5c3',
      outline: 0,
      boxShadow: 'none'
    },
  }),
}

const selectGrayStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    minHeight: '42px',
    border: 'none',
    outline: 0,
    borderRadius: '0.475rem',
    boxShadow: 'none',
    backgroundColor: '#f5f8fa',
    borderColor: '#f5f8fa',
    color: '#5e6278',
    transition: 'color 0.2s ease, background-color 0.2s ease',
    '&:focus': {
      backgroundColor: '#eef3f7',
      borderColor: '#eef3f7 !important',
      outline: 0,
      boxShadow: 'none'
    },
    '&:hover': {
      backgroundColor: '#eef3f7',
      borderColor: '#eef3f7 !important',
      outline: 0,
      boxShadow: 'none'
    },
  }),
}

const AsyncSelect2: React.FC<any> = ({
  isGray,
  sourceUrl,
  ...otherProps
}) => {
  let timer: any = null;
  const loadOptions = (inputValue: string, callback: any) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const data: any = await axios.get(`${sourceUrl}?search=${inputValue}`);
      let options = [];
      if (data.data?.data) {
        options = data.data.data.map((item: any) => ({
          label: item.name,
          value: item.id,
        }));
      }
      callback(options);
    }, 1000);
  };

  return (
    <AsyncSelect
      {...otherProps}
      loadOptions={loadOptions}
      styles={isGray ? selectGrayStyles : selectStyles}
    />
  )
}

export {AsyncSelect2}
