import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

type Props = {
    handleChangeFiles: (files: any) => void
    name?: string
    disabled?: boolean
    isLoad?: boolean
    multiFile?: boolean
}

const UploadFile: React.FC<Props> = ({disabled, isLoad, multiFile, handleChangeFiles }) => {
    const [uploadFiles, setUploadFiles] = useState<any[]>([])

    const onDrop = useCallback(files => {
        setUploadFiles(files)
        if (handleChangeFiles) {
            !multiFile ? handleChangeFiles(files[0]) : handleChangeFiles(files)
        }
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled: disabled,
        accept: ".csv, application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

    })

    const handleRemoveSelect = () => {
        setUploadFiles([])
        if (handleChangeFiles) {
            !multiFile ? handleChangeFiles(null) : handleChangeFiles([])
        }
    }

    const fileEls = uploadFiles.map((file, index) => (
        <li key={index} className="d-flex align-items-center py-2">
            <span className="bullet bg-success h-15px w-25px me-5"></span>{file.path}
            <label className="btn" onClick={handleRemoveSelect}>
                <i className="bi bi-backspace"></i>
            </label>
        </li>
    ));

    return (
        <section className= "dropzone-custom">
            <div { ...getRootProps({ className: 'dropzone-input' }) }>
                <input { ...getInputProps() } multiple={multiFile}/>
                { !isLoad ? "Drag & Drop to import" : "Importing visicom, please wait ..." }
            </div>
            { uploadFiles && (
                <aside className="dropzone-files mt-2">
                    <ul className="pl-3">{fileEls}</ul>
                </aside>
            )}
        </section>
    )
}

export { UploadFile }
