/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { UserModel } from '../../../../app/models/UserModel'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user) as UserModel
  if (user.roles[0].name == 'partner') {
    return (
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/partner-install-report'
          icon='/media/icons/duotune/files/fil005.svg'
          title={intl.formatMessage({id: 'MENU.PARTNER_INSTALL_REPORT'})}
          fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/revenue-report'
          icon='/media/icons/duotune/files/fil005.svg'
          title={intl.formatMessage({id: 'MENU.REVENUE_REPORT'})}
          fontIcon='bi-layers'
        />
      </>
    )
  }
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/account-management'
        icon='/media/icons/duotune/communication/com005.svg'
        title={intl.formatMessage({id: 'MENU.ACCOUNT_MANAGEMENT'})}
        fontIcon='bi-layers'
      />
     <AsideMenuItem
        to='/webstar-management'
        icon='/media/icons/duotune/finance/fin006.svg'
        title={intl.formatMessage({id: 'MENU.WEBSTAR_MANAGEMENT'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/best-chrome-extension'
        icon='/media/icons/duotune/general/gen029.svg'
        title={intl.formatMessage({id: 'MENU.BEST_CHROME_EXTENSION'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/cause'
        icon='/media/icons/duotune/coding/cod008.svg'
        title={intl.formatMessage({id: 'MENU.CAUSE'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/campaign-management'
        icon='/media/icons/duotune/general/gen016.svg'
        title={intl.formatMessage({id: 'MENU.CAMPAIGN'})}
        />
      <AsideMenuItem
        to='/install-report'
        icon='/media/icons/duotune/files/fil002.svg'
        title={intl.formatMessage({id: 'MENU.INSTALL_REPORT'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/revenue-import'
        icon='/media/icons/duotune/files/fil005.svg'
        title={intl.formatMessage({id: 'MENU.REVENUE_IMPORT'})}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/report-summary'
        icon='/media/icons/duotune/files/fil005.svg'
        title={intl.formatMessage({id: 'MENU.REPORT_SUMMARY'})}
        fontIcon='bi-layers'
      />

    </>
  )
}
