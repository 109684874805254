export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { access_token },
      } = store.getState()

      if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`
      }
      document.body.classList.add('page-loading')
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => {
      document.body.classList.remove('page-loading')
      return Promise.resolve(response)
    },
    (error: any) => {
      document.body.classList.remove('page-loading')
      return Promise.reject(error)
    }
  );
}
