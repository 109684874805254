import React, { useState } from "react"

type Props = {
    handleSortClick: (name: string, type_sort: string) => void
    name: string,
    type_sort: string,
    sort_column: string,
    children: React.ReactNode
}

const THSort: React.FC<Props> = ({handleSortClick, name, type_sort, sort_column, children}) => {
    return (
        <th className={(sort_column === name) ? 'sorting actived sorting_'+ type_sort : 'sorting sorting_desc'} key={name+"1"} onClick={() => handleSortClick(name, type_sort)}
        >{children}</th>
    )
  }

export { THSort }
