/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'

type Props = {
  selected: number,
  onChange: (e: any) => void
}

const DATA_PAGINATION_LENGTH = [20, 50, 100]

const PaginationLength: React.FC<Props> = ({
  selected,
  onChange
}) => {
  return (
    <div className="d-flex align-items-center">
      <span className="me-2">Show</span>
      <select className="form-select form-select-sm" onChange={(e) => onChange(e)} value={selected || DATA_PAGINATION_LENGTH[0]}>
        {DATA_PAGINATION_LENGTH.map((item: number) => (
          <option key={`pagination-length-${item}`} value={item}>{item}</option>
        ))}
      </select>
      <span className="ms-2">entries</span>
    </div>
  )
}

export {PaginationLength, DATA_PAGINATION_LENGTH}
