import React, {Suspense, lazy} from 'react'
import { useSelector } from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import { RootState } from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import { UserModel } from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user) as UserModel
  const AccountManagementWrapper = lazy(() => import('../pages/account-management/AccountManagementWrapper'))
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'));
  const BestChromeExtensionWrapper = lazy(() => import('../pages/best-chrome-extension/BestChromeExtensionWrapper'));
  const CauseWrapper = lazy(() => import('../pages/cause/CauseWrapper'));
  const PartnerManagementWrapper = lazy(() => import('../pages/partner-management/PartnerManagementWrapper'));
  const CampaignManagementWrapper = lazy(() => import('../pages/campaign-management/CampaignManagementWrapper'))
  const RevenueImportWrapper = lazy(() => import('../pages/revenue-import/RevenueImportWrapper'));
  const InstallReportWrapper = lazy(() => import('../pages/install-report/InstallReportWrapper'));
  const PartnerInstallReportWrapper = lazy(() => import('../pages/partner-install-report/PartnerInstallReportWrapper'));
  const ReportSummaryWrapper = lazy(() => import('../pages/report-summary/ReportSummaryWrapper'));
  const CauseDetailReportWrapper = lazy(() => import('../pages/cause-detail-report/CauseDetailReportWrapper'));
  const RevenueReportWrapper = lazy(() => import('../pages/partner-revenue-report/RevenueReportWrapper'));
  if (user.roles[0].name === 'partner') {
    return (
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path='/dashboard' component={DashboardWrapper} />
          <Route path='/partner-install-report' component={PartnerInstallReportWrapper} />
          <Route path='/revenue-report' component={RevenueReportWrapper} />
          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/account-management' component={AccountManagementWrapper} />
        <Route path='/best-chrome-extension' component={BestChromeExtensionWrapper} />
        <Route path='/cause' component={CauseWrapper} />
        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/webstar-management' component={PartnerManagementWrapper} />
        <Route path='/campaign-management' component={CampaignManagementWrapper} />
        <Route path='/revenue-import' component={RevenueImportWrapper} />
        <Route path='/install-report' component={InstallReportWrapper} />
        <Route path='/report-summary' component={ReportSummaryWrapper} />
        <Route path='/report-summary-detail/:id' component={CauseDetailReportWrapper} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
  
}
