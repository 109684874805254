/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React from 'react'
import ReactPaginate from 'react-paginate'

type Props = {
  pageCount: number
  initialPage?: number
  forcePage?: number
  pageRangeDisplayed?: number
  marginPagesDisplayed?: number
  onPageChange: (data: any) => void
}

const Paginate: React.FC<Props> = ({
  pageCount,
  initialPage,
  forcePage,
  pageRangeDisplayed,
  marginPagesDisplayed,
  onPageChange
}) => {
  return (
    <ReactPaginate
      pageCount={pageCount}
      onPageChange={onPageChange}
      initialPage={initialPage || 0}
      forcePage={forcePage || 0}
      pageRangeDisplayed={pageRangeDisplayed || 3}
      marginPagesDisplayed={marginPagesDisplayed || 3}
      containerClassName={'pagination'}
      activeClassName={'active'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item previous'}
      nextClassName={'page-item next'}
      previousLabel={<i className="previous"></i>}
      nextLabel={<i className="next"></i>}
      previousLinkClassName={'page-link'}
      nextLinkClassName={'page-link'}
      disableInitialCallback={true}
    />
  )
}

export {Paginate}
